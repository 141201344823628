/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-13 13:35:08
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { eduLevel, lookStatus, getType } from '@/utils/options';
export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'id',
    itemProps: {
      label: '项目编码',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'name',
    itemProps: {
      label: '项目名称',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'firstCategoryName',
    itemProps: {
      label: '项目分类',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'price',
    itemProps: {
      label: '价格',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'salesVolume',
    itemProps: {
      label: '销量',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'createAt',
    itemProps: {
      label: '创建时间',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'status',
    itemProps: {
      label: '状态',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } }];});






// form 默认值
export var formData = function formData() {return {};};